import firebase from '@/firebase'
const auth = firebase.auth
// const db = firebase.db

const state = {
  uid: '',
  firstName: '',
  lastName: '',
  email: '',
  isLoggedIn: false,
  initialized: false
}

const getters = {
  authUser: state => {
    return {
      uid: state.uid,
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      isLoggedIn: state.isLoggedIn,
      initialized: state.initialized
    }
  }
}

const actions = {
  initAuth (context) {
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged(user => {
        if (user) {
          user.getIdToken(true)
          auth.currentUser.getIdTokenResult()
            .then(() => {})
          context.dispatch('getCurrentUser', {
            userObject: user
          }).then(() => {
            resolve(user)
          })
        } else {
          context.dispatch('getCurrentUser', {
            userObject: null
          })
          resolve()
        }
      }, reject)
    })
  },
  signIn (context) {
    var provider = new firebase.firebase.auth.GoogleAuthProvider()
    return auth
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        console.log(token)
        console.log(user)
        this.firebaseUser = user
        const userData = {
          uid: user.uid,
          email: user.email
        }
        context.commit('setCurrentUser', userData)
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        console.log(errorCode)
        console.log(errorMessage)
        console.log(email)
        console.log(credential)
      })
  },
  signOut () {
    return auth.signOut().catch((error) => {
      console.log(error)
    })
  },
  getCurrentUser (context, payload) {
    if (payload.userObject) {
      const userData = {
        uid: payload.userObject.uid,
        email: payload.userObject.email,
      }
      return context.commit('setCurrentUser', userData)
      /* return db.collection('users').doc(payload.userObject.email).get().then(function (snapshot) {
          const data = snapshot.data()
          console.log(data)
      }) */
    } else {
      context.commit('setCurrentUser')
    }
  }
}

const mutations = {
  setCurrentUser (state, payload) {
    if (payload) {
      state.uid = payload.uid
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      state.email = payload.email
      state.isLoggedIn = true
      state.initialized = true
    } else {
      state.isLoggedIn = false
      state.initialized = true
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
