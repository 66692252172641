import firebase from '@firebase/app'
import '@firebase/firestore'
import '@firebase/auth'
import '@firebase/storage'
import '@firebase/functions'
import firebaseConfig from '@/firebase-config'

console.log('Firebase imported')

firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()
const functions = firebase.functions()
const firestore = firebase.firestore

export default {
  auth,
  db,
  storage,
  functions,
  firestore,
  firebase
}
