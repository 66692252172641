import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import servers from './modules/servers'
import models from './modules/models'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    servers,
    models
  }
})
