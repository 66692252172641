import firebase from '@/firebase'
const db = firebase.db

const state = {
  serverLogs: null
}

const getters = {
  serverLogs: state => {
    return state.serverLogs
  }
}

const actions = {
  getServerLogs (context) {
      return db.collection('serverLogs').orderBy('queryTimestamp', 'desc').limit(1).onSnapshot(snapshot => {
          snapshot.forEach(doc => {
            context.commit('setServerLogs', doc.data())
          })
          return
      })
  }
}

const mutations = {
  setServerLogs (state, data) {
    state.serverLogs = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
