import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// Checks if a route has query params
// Used for keeping query params between routes
function hasQueryParams (route) {
  return !!Object.keys(route.query).length
}

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/main',
      name: 'main',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "main" */ './components/Main.vue')
    },
    {
      path: '/serverLogs',
      name: 'serverLogs',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "serverLogs" */ './components/ServerLogs.vue')
    },
    {
      path: '/models',
      name: 'models',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "models" */ './components/Models.vue')
    },
    {
      path: '/',
      redirect: '/main'
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  // Keep initial query params between routes (will override any new params)
  if (to && from && !hasQueryParams(to) && hasQueryParams(from)) {
    next(Object.assign({}, to, { query: from.query }))
  } else {
    next()
  }
})

export default router
