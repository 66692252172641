import firebase from '@/firebase'
const db = firebase.db

const state = {
  modelLogs: {}
}

const getters = {
  modelLogs: state => {
    return state.modelLogs
  }
}

const actions = {
  getModelLogs (context, payload) {
      return db.collection('modelLogs').where('projectId', '==', payload.projectId).orderBy('timestamp', 'desc').limit(50).onSnapshot(snapshot => {
          let logs = []
          snapshot.forEach(doc => {
            logs.push(doc.data())
            context.commit('setModelLogs', {
              logs: logs,
              projectId: payload.projectId
            })
          })
          return
      })
  }
}

const mutations = {
  setModelLogs (state, data) {
    state.modelLogs = {
      ...state.modelLogs,
      [data.projectId]: data.logs
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
